import { template as template_9114c2ff675d46599a9140329ce082e4 } from "@ember/template-compiler";
const FKText = template_9114c2ff675d46599a9140329ce082e4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_1d4a7679588b4e9e97f0bda263a41fe7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1d4a7679588b4e9e97f0bda263a41fe7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

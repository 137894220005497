import { template as template_20c882022eaf4d27be11782cd0372c09 } from "@ember/template-compiler";
const FKLabel = template_20c882022eaf4d27be11782cd0372c09(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

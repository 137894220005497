import { template as template_e30a2ebcd48a47daa74f0413f4902171 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e30a2ebcd48a47daa74f0413f4902171(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
